<!--
 * @Author: sun
 * @Date: 2024-03-07 14:53:30
 * @LastEditTime: 2024-10-10 12:55:15
 * @FilePath: \cloud-platform\src\views\coupon\Coupons.vue
 * @Description: 
-->
<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/Welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>优惠券</el-breadcrumb-item>
      <el-breadcrumb-item>商家优惠券</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card v-if="isShowData">
      <!-- 查询 -->
      <div
        style="
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
        "
      >
        <label style="margin: 10px" for=""
          >停车场：
          <el-select
            v-model="query.pkId"
            filterable
            placeholder="请选择"
            style="width: 200px"
            size="small"
            @change="pkIdChange"
          >
            <el-option
              v-for="item in PkInfoSelect"
              :key="item.id"
              :label="item.pkName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </label>
        <label style="margin: 10px" for=""
          >商家名称：
          <el-select
            v-model="query.shopId"
            filterable
            placeholder="请输入商家名称"
            style="width: 150px"
            size="small"
            clearable
          >
            <el-option
              v-for="item in businessNameSelect"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </label>
        <label style="margin: 10px" for=""
          >优惠券名称：
          <el-input
            style="width: 200px"
            size="small"
            placeholder="请输入优惠券名称"
            v-model="query.couponName"
            clearable
          />
        </label>
        <label style="margin: 10px" for=""
          >优惠券类型：
          <el-select
            v-model="query.couponType"
            clearable
            placeholder="请选择"
            style="width: 200px"
            size="small"
          >
            <el-option label="优惠券" :value="1"></el-option>
            <!--            <el-option label="线下纸质优惠券" :value="2"></el-option>-->
          </el-select>
        </label>
        <label style="margin: 10px" for=""
          >优惠券状态：
          <el-select
            v-model="query.isEnable"
            clearable
            placeholder="请选择"
            style="width: 200px"
            size="small"
          >
            <el-option label="草稿" :value="0"></el-option>
            <el-option label="已发放" :value="1"></el-option>
            <el-option label="已作废" :value="2"></el-option>
          </el-select>
        </label>
        <label style="margin: 10px" for=""
          >创建时间：<el-date-picker
            v-model="startTime"
            type="datetime"
            value-format="timestamp"
            size="small"
            placeholder="选择日期时间"
          >
          </el-date-picker>
          至
          <el-date-picker
            v-model="endTime"
            type="datetime"
            value-format="timestamp"
            size="small"
            default-time="23:59:59"
            placeholder="选择日期时间"
            @change="inTimeEndchange"
          >
          </el-date-picker
        ></label>
        <!-- <label style="margin: 10px" for=""
          >是否发放：
          <el-select
            v-model="query.isEnable"
            placeholder="请选择"
            style="width: 200px"
            size="small"
            clearable
          >
            <el-option label="草稿" :value="0"></el-option>
            <el-option label="已发放" :value="1"></el-option>
          </el-select>
        </label> -->
        <el-button
          style="margin-left: 10px"
          type="primary"
          size="small"
          @click="shopCouponcx"
          >查询</el-button
        >
        <el-button size="small" @click="shopCouponcz()">重置</el-button>
      </div>
      <!-- 点击添加显示对话框 -->
      <el-button
        type="primary"
        v-if="isShowbtn"
        @click="isShowAddDialog()"
        size="small"
        icon="el-icon-plus"
        >添加</el-button
      >
      <el-button
        type="primary"
        size="small "
        icon="el-icon-plus"
        :loading="isShowImprot"
        @click="improtClick"
        >导出</el-button
      >
      <hr />
      <!-- 表格 -->
      <transition appear tag="div">
        <el-table
          v-loading="loading"
          ref="multipleTable"
          :data="tableData"
          stripe
          tooltip-effect="dark"
          style="width: 100%"
        >
          <el-table-column
            header-align="left"
            align="left"
            prop="id"
            label="编号"
            width="60"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="shopInfo.shopName"
            label="商家"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="couponName"
            label="优惠券名称"
            width="100"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="couponType"
            label="优惠类型"
            width="100"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.couponType == 1">电子优惠券</span>
              <span v-if="scope.row.couponType == 2">线下优惠券</span>
              <span v-if="scope.row.couponType == 3">折扣优惠券</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="needMoney"
            label="满多少金额"
            width="100"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="couponMoney"
            label="优惠券金额"
            width="100"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="quality"
            label="优惠券数量"
            width="100"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="userMaxNums"
            label="最大领取数量"
            width="100"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="remainNum"
            label="优惠券剩余数量"
            width="120"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop=""
            label="优惠券已领取数量"
            width="140"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.quality - scope.row.remainNum }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="validTime"
            label="领取时效"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.validTime }} 天</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="couponTime"
            label="领券后有效期"
            width="150"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.couponTime }} 天</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="createTime"
            label="创建时间"
            width="150"
          >
            <template slot-scope="scope">
              <span>{{ conversionTime(scope.row.createTime) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="createTime"
            label="到期时间"
            width="150"
          >
            <template slot-scope="scope">
              <span>{{
                computDate(scope.row.createTime, scope.row.validTime)
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="wxQrcodeUrl"
            label="通用H5"
            width="100"
          >
            <template slot-scope="scope">
              <el-image
                style="width: 40px; height: 40px"
                :src="hqh5QrcodeUrl(scope)"
                :preview-src-list="[hqh5QrcodeUrl(scope)]"
              >
                <template #fallback>
                  <div class="image-fallback">图片加载失败</div>
                </template>
              </el-image>
            </template>
          </el-table-column>
          <!-- <el-table-column
            header-align="left"
            align="left"
            prop="wxQrcodeUrl"
            label="微信二维码"
            width="100"
          >
            <template slot-scope="scope" v-if="scope.row.couponType != 3">
              <el-image
                style="width: 40px; height: 40px"
                :src="scope.row.wxQrcodeUrl"
                :preview-src-list="[scope.row.wxQrcodeUrl]"
              >
              </el-image>
            </template>
          </el-table-column> -->
          <el-table-column
            header-align="left"
            align="left"
            prop="alipayQrcodeUrl"
            label="支付宝二维码"
            width="110"
          >
            <template slot-scope="scope" v-if="scope.row.couponType != 3">
              <el-image
                style="width: 40px; height: 40px"
                :src="scope.row.alipayQrcodeUrl"
                :preview-src-list="[scope.row.alipayQrcodeUrl]"
              >
              </el-image>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="isEnable"
            label="是否发放"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.isEnable === 0">草稿</span>
              <span v-if="scope.row.isEnable === 1">已发放</span>
              <span v-if="scope.row.isEnable === 2">已作废</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            label="操作"
            :width="isShowbtn ? 200 : 130"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                v-if="
                  (isShowbtn &&
                    scope.row.qrcodeType == 1 &&
                    scope.row.couponType == 3) ||
                    (scope.row.qrcodeType == 1 && scope.row.couponType == 1)
                "
                @click="yhqClickIsShow(scope)"
                size="mini"
                >查看/打印</el-button
              >
              <router-link
                class="screen"
                target="_blank"
                :to="{
                  path: '/dynamicCode',
                  query: {
                    id: scope.row.id,
                    expireTime: computDate(
                      scope.row.createTime,
                      scope.row.validTime
                    ),
                    name: scope.row.couponName,
                    discountValue: scope.row.discountValue,
                    pkid: scope.row.pkId,
                    shopName: scope.row.shopInfo.shopName,
                    couponType: scope.row.couponType,
                  },
                }"
                v-if="
                  (scope.row.qrcodeType == 2 && scope.row.couponType == 3) ||
                    (scope.row.qrcodeType == 2 && scope.row.couponType == 1)
                "
                >查看/打印</router-link
              >
              <el-button
                type="text"
                v-if="scope.row.isEnable == 1"
                @click="zfClick(scope)"
                size="mini"
                >作废</el-button
              >
              <el-button
                v-if="scope.row.isEnable == 0"
                type="text"
                @click="editClickIsShow(scope)"
                size="mini"
                icon="el-icon-share"
                >编辑</el-button
              >
              <el-button
                type="text"
                @click="PhysicalCouponList(scope)"
                size="mini"
                >发放实体券</el-button
              >
              <!-- <el-button
                v-if="isShowbtn && scope.row.isEnable === 0"
                type="text"
                @click="delClick(scope)"
                size="mini"
                icon="el-icon-delete"
                >删除</el-button
              > -->
            </template>
          </el-table-column>
        </el-table>
      </transition>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.current"
        :page-sizes="[10, 50, 100]"
        :page-size="query.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 优惠券领取 -->
    <el-dialog
      title="优惠券领取"
      :visible.sync="isShowyhq"
      width="50%"
      v-el-drag-dialog
    >
      <div class="yhq" id="printCons" v-if="yhqData">
        <div class="yhq-yi">
          <p>
            优惠券名称： <span>{{ yhqData.couponName }}</span>
          </p>
          <el-button v-if="yhqData.isEnable == 1" size="small" @click="dyClick"
            >打印</el-button
          >
        </div>
        <p>
          优惠券面额： <span>{{ yhqData.couponMoney }}元</span>
        </p>
        <p>
          每个用户最大领取数量： <span>{{ yhqData.userMaxNums }}张</span>
        </p>
        <p>
          使用说明：请扫一扫二维码领取，微信扫微信二维码，支付宝扫支付宝二维码
        </p>
        <div class="yhqtu">
          <div class="tu-z">
            <p>H5通用</p>
            <el-image
              style="width: 200px; height: 200px"
              :src="imageUrl"
              :preview-src-list="[imageUrl]"
            >
              <template #fallback>
                <div class="image-fallback">图片加载失败</div>
              </template>
            </el-image>
          </div>
          <!-- <div class="tu-z">
            <p>微信扫一扫</p>
            <el-image
              style="width: 200px; height: 200px"
              :src="yhqData.wxQrcodeUrl"
              :preview-src-list="[yhqData.wxQrcodeUrl]"
            >
            </el-image>
          </div> -->
          <div class="tu-y">
            <p>支付宝扫一扫</p>
            <el-image
              style="width: 200px; height: 200px"
              :src="yhqData.alipayQrcodeUrl"
              :preview-src-list="[yhqData.alipayQrcodeUrl]"
            >
            </el-image>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 添加 -->
    <el-dialog
      title="添加商家优惠券"
      :visible.sync="isShowAdd"
      @close="addClose"
      width="70%"
      v-el-drag-dialog
    >
      <el-form
        :model="addForm"
        :rules="formRules"
        ref="addRef"
        label-width="160px"
        class="demo-ruleForm"
      >
        <el-form-item label="扣款停车场" prop="pkId">
          <el-select
            v-model="addForm.pkId"
            placeholder="请选择"
            @change="jineChange"
          >
            <el-option
              v-for="item in PkInfoSelect"
              :key="item.id"
              :label="item.pkName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="适用停车场">
          <span>{{ pkNames }}</span>
        </el-form-item>
        <el-form-item label="商家名称" prop="shopId">
          <span>{{ addshopId }}</span>
        </el-form-item>
        <el-form-item label="优惠券类型" prop="couponType">
          <el-select
            v-model="addForm.couponType"
            placeholder="请选择"
            @change="changeType"
          >
            <el-option label="优惠券" :value="1"></el-option>
            <!--            <el-option label="线下纸质优惠券" :value="2"></el-option>-->
            <el-option
              label="折扣券(如5折，9折等，含全免券)"
              :value="3"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="二维码类型"
          prop="qrcodeType"
          v-if="addForm.couponType === 3 || addForm.couponType === 1"
        >
          <el-radio-group v-model="addForm.qrcodeType">
            <el-radio label="1">固定二维码</el-radio>
            <el-radio label="2">动态二维码</el-radio>
          </el-radio-group>
        </el-form-item>
        <!-- <el-form-item label="是否可叠加" prop="isMultiUsed">
          <el-select v-model="addForm.isMultiUsed" placeholder="请选择">
            <el-option label="可叠加" :value="1"></el-option>
            <el-option label="不可叠加" :value="0"></el-option>
          </el-select>
        </el-form-item> -->
        <div v-if="addForm.couponType === 3">
          <el-form-item label="折扣值" prop="discountValue">
            <el-input
              v-model="addForm.discountValue"
              placeholder="请输入折扣值"
              style="width: 180px"
            ></el-input>
            <span>5折，填0.5</span>
          </el-form-item>
        </div>
        <div v-if="addForm.couponType === 1 || addForm.couponType === 3">
          <el-form-item label="优惠券名称" prop="couponName">
            <el-input
              v-model="addForm.couponName"
              placeholder="请输入优惠券名称"
              style="width: 180px"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="单张最大的优惠金额"
            prop="maxCouponMoney"
            v-if="addForm.couponType === 3"
          >
            <el-input
              v-model="addForm.maxCouponMoney"
              placeholder="请输入单张最大的优惠金额"
              style="width: 180px"
            ></el-input>
          </el-form-item>
          <el-row>
            <el-col :span="7">
              <el-form-item label="停车金额满多少元" prop="needMoney">
                <el-input v-model="addForm.needMoney"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6" v-if="addForm.couponType !== 3">
              <el-form-item
                label="优惠券金额(元)"
                label-width="120px"
                prop="couponMoney"
              >
                <el-input v-model="addForm.couponMoney"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="优惠券数量" prop="quality">
            <el-input
              v-model="addForm.quality"
              placeholder="请输入优惠券数量"
              style="width: 180px"
            ></el-input>
          </el-form-item>
          <el-form-item label="优惠券剩余额度">
            <span>{{
              totalCouponMoney ? totalCouponMoney + "元" : "0元"
            }}</span>
          </el-form-item>
          <el-form-item label="每个用户最大领取数" prop="userMaxNums">
            <el-input
              v-model="addForm.userMaxNums"
              placeholder="请输入每个用户最大领取数"
              style="width: 100px"
            ></el-input>
          </el-form-item>
          <el-row>
            <el-col :span="12">
              <el-form-item label="领取时效" prop="validTime">
                <el-input
                  type="number"
                  v-model="addForm.validTime"
                  placeholder="请输入领取时效"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="选择时长单位" prop="validTimeCompany">
                <!-- @change="$forceUpdate()"  强制刷新视图 -->
                <el-select
                  v-model="addForm.validTimeCompany"
                  placeholder="请选择"
                  @change="$forceUpdate()"
                >
                  <el-option label="小时" :value="0"></el-option>
                  <el-option label="天" :value="1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="领券后有效期" prop="couponTime">
                <el-input
                  type="number"
                  v-model="addForm.couponTime"
                  placeholder="请输入优惠时长"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="选择时长单位" prop="couponTimeCompany">
                <!-- @change="$forceUpdate()"  强制刷新视图 -->
                <el-select
                  v-model="addForm.couponTimeCompany"
                  placeholder="请选择"
                  @change="$forceUpdate()"
                >
                  <el-option label="小时" :value="0"></el-option>
                  <el-option label="天" :value="1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="备注" prop="remarks">
            <el-input
              type="textarea"
              v-model="addForm.remarks"
              placeholder="请输入备注"
            ></el-input>
          </el-form-item>
        </div>
        <div v-if="addForm.couponType === 2">
          <el-form-item label="优惠券名称" prop="couponName">
            <el-input
              v-model="addForm.couponName"
              placeholder="请输入优惠券名称"
              style="width: 180px"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="消费金额多个范围配置"
            style="margin-bottom: 0"
          ></el-form-item>
          <div class="mains">
            <div class="bodfexl">
              <div>
                <div
                  style="margin-bottom: 10px"
                  v-for="(item, index) in addForm.conponMutiInfos"
                  :key="index"
                >
                  <span style="margin-right: 20px"
                    >消费金额：<el-input
                      v-model="item.minConsumeMoney"
                      style="width: 70px"
                      size="small"
                    ></el-input>
                    到
                    <el-input
                      v-model="item.maxConsumeMoney"
                      style="width: 70px"
                      size="small"
                    ></el-input>
                    元</span
                  >
                  <span
                    >优惠金额：<el-input
                      v-model="item.couponMoney"
                      style="width: 70px"
                      size="small"
                    ></el-input>
                    元
                    <el-button size="small" @click="delClickone(index)"
                      >删除</el-button
                    ></span
                  >
                </div>
              </div>
              <div class="dwbtn">
                <el-button class="btn" size="small" @click="addOneClick"
                  >增加一行</el-button
                >
              </div>
            </div>
          </div>
          <el-row>
            <el-col :span="12">
              <el-form-item label="领取时效" prop="validTime">
                <el-input
                  type="number"
                  v-model="addForm.validTime"
                  placeholder="请输入领取时效"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="选择时长单位" prop="validTimeCompany">
                <!-- @change="$forceUpdate()"  强制刷新视图 -->
                <el-select
                  v-model="addForm.validTimeCompany"
                  placeholder="请选择"
                  @change="$forceUpdate()"
                >
                  <el-option label="小时" :value="0"></el-option>
                  <el-option label="天" :value="1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="备注" prop="remarks">
            <el-input
              type="textarea"
              v-model="addForm.remarks"
              placeholder="请输入备注"
            ></el-input>
          </el-form-item>
          <el-form-item label="优惠券金额(元)" style="display: none">
            <el-input
              v-model="addForm.couponMoney"
              placeholder="请输入优惠券金额，例如：30"
            ></el-input>
          </el-form-item>
          <el-form-item label="优惠券数量" style="display: none">
            <el-input
              v-model="addForm.quality"
              placeholder="请输入优惠券数量"
              style="width: 180px"
            ></el-input>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addClick1">保存草稿箱</el-button>
        <el-button type="primary" @click="addClick2">直接发放</el-button>
      </span>
    </el-dialog>
    <!-- 编辑 -->
    <el-dialog
      title="编辑商家优惠券"
      :visible.sync="isShowEdit"
      width="70%"
      v-el-drag-dialog
    >
      <el-form
        :model="editForm"
        :rules="formRules"
        label-width="160px"
        class="demo-ruleForm"
      >
        <el-form-item label="扣款停车场" prop="pkId">
          <el-select
            v-model="editForm.pkId"
            placeholder="请选择"
            @change="jineChange"
          >
            <el-option
              v-for="item in PkInfoSelect"
              :key="item.id"
              :label="item.pkName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="适用停车场">
          <span>{{ pkNames }}</span>
        </el-form-item>
        <el-form-item label="商家名称" prop="shopId">
          <span>{{ addshopId }}</span>
        </el-form-item>
        <el-form-item
          label="二维码类型"
          prop="qrcodeType"
          v-if="editForm.couponType === 3"
        >
          <el-radio-group v-model="editForm.qrcodeType">
            <el-radio label="1">固定二维码</el-radio>
            <el-radio label="2">动态二维码</el-radio>
          </el-radio-group>
        </el-form-item>
        <div v-if="editForm.couponType === 3">
          <el-form-item label="折扣值" prop="discountValue">
            <el-input
              v-model="editForm.discountValue"
              placeholder="请输入折扣值"
              style="width: 180px"
            ></el-input>
            <span>5折，填0.5</span>
          </el-form-item>
        </div>
        <div v-if="editForm.couponType === 1 || editForm.couponType === 3">
          <el-form-item label="优惠券名称" prop="couponName">
            <el-input
              v-model="editForm.couponName"
              placeholder="请输入优惠券名称"
              style="width: 180px"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="单张最大的优惠金额"
            prop="maxCouponMoney"
            v-if="editForm.couponType === 3"
          >
            <el-input
              v-model="editForm.maxCouponMoney"
              placeholder="请输入单张最大的优惠金额"
              style="width: 180px"
            ></el-input>
          </el-form-item>
          <el-row>
            <el-col :span="7">
              <el-form-item label="停车金额满多少元" prop="needMoney">
                <el-input v-model="editForm.needMoney"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6" v-if="editForm.couponType !== 3">
              <el-form-item
                label="优惠券金额(元)"
                label-width="120px"
                prop="couponMoney"
              >
                <el-input v-model="editForm.couponMoney"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="优惠券数量" prop="quality">
            <el-input
              v-model="editForm.quality"
              placeholder="请输入优惠券数量"
              style="width: 180px"
            ></el-input>
          </el-form-item>
          <el-form-item label="优惠券剩余额度">
            <span>{{
              totalCouponMoney ? totalCouponMoney + "元" : "0元"
            }}</span>
          </el-form-item>
          <el-form-item label="每个用户最大领取数" prop="userMaxNums">
            <el-input
              v-model="editForm.userMaxNums"
              placeholder="请输入每个用户最大领取数"
              style="width: 100px"
            ></el-input>
          </el-form-item>
          <el-row>
            <el-col :span="12">
              <el-form-item label="领取时效" prop="validTime">
                <el-input
                  type="number"
                  v-model="editForm.validTime"
                  placeholder="请输入领取时效"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="选择时长单位" prop="validTimeCompany">
                <el-select
                  v-model="editForm.validTimeCompany"
                  placeholder="请选择"
                  @change="$forceUpdate()"
                >
                  <el-option label="小时" :value="0"></el-option>
                  <el-option label="天" :value="1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="领券后有效期" prop="couponTime">
                <el-input
                  type="number"
                  v-model="editForm.couponTime"
                  placeholder="请输入优惠时长"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="选择时长单位" prop="couponTimeCompany">
                <el-select
                  v-model="editForm.couponTimeCompany"
                  placeholder="请选择"
                  @change="$forceUpdate()"
                >
                  <el-option label="小时" :value="0"></el-option>
                  <el-option label="天" :value="1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="备注" prop="remarks">
            <el-input
              type="textarea"
              v-model="editForm.remarks"
              placeholder="请输入备注"
            ></el-input>
          </el-form-item>
        </div>
        <div v-if="editForm.couponType === 2">
          <el-form-item label="优惠券名称" prop="couponName">
            <el-input
              v-model="editForm.couponName"
              placeholder="请输入优惠券名称"
              style="width: 180px"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="消费金额多个范围配置"
            style="margin-bottom: 0"
          ></el-form-item>
          <div class="mains">
            <div class="bodfexl">
              <div>
                <div
                  style="margin-bottom: 10px"
                  v-for="(item, index) in editForm.conponMutiInfos"
                  :key="index"
                >
                  <span style="margin-right: 20px"
                    >消费金额：<el-input
                      v-model="item.minConsumeMoney"
                      style="width: 70px"
                      size="small"
                    ></el-input>
                    到
                    <el-input
                      v-model="item.maxConsumeMoney"
                      style="width: 70px"
                      size="small"
                    ></el-input>
                    元</span
                  >
                  <span
                    >优惠金额：<el-input
                      v-model="item.couponMoney"
                      style="width: 70px"
                      size="small"
                    ></el-input>
                    元
                    <el-button size="small" @click="delClickone(index)"
                      >删除</el-button
                    ></span
                  >
                </div>
              </div>
              <div class="dwbtn">
                <el-button class="btn" size="small" @click="addOneClick"
                  >增加一行</el-button
                >
              </div>
            </div>
          </div>
          <el-row>
            <el-col :span="12">
              <el-form-item label="领取时效" prop="validTime">
                <el-input
                  type="number"
                  v-model="editForm.validTime"
                  placeholder="请输入领取时效"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="选择时长单位" prop="validTimeCompany">
                <el-select
                  v-model="editForm.validTimeCompany"
                  placeholder="请选择"
                  @change="$forceUpdate()"
                >
                  <el-option label="小时" :value="0"></el-option>
                  <el-option label="天" :value="1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="备注" prop="remarks">
            <el-input
              type="textarea"
              v-model="editForm.remarks"
              placeholder="请输入备注"
            ></el-input>
          </el-form-item>
          <el-form-item label="优惠券金额(元)" style="display: none">
            <el-input
              v-model="editForm.couponMoney"
              placeholder="请输入优惠券金额，例如：30"
            ></el-input>
          </el-form-item>
          <el-form-item label="优惠券数量" style="display: none">
            <el-input
              v-model="editForm.quality"
              placeholder="请输入优惠券数量"
              style="width: 180px"
            ></el-input>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editClick1">保存草稿箱</el-button>
        <el-button type="primary" @click="editClick2">直接发放</el-button>
      </span>
    </el-dialog>
    <!-- 查看实体券 -->
    <el-dialog
      title="实体券"
      :visible.sync="isShowphysicalCoupon"
      width="70%"
      v-el-drag-dialog
    >
      <div class="entitycenter">
        <div class="codelist">
          <!-- 列表 -->
          <div class="listofqrcodes">
            <el-table
              v-loading="loading"
              ref="physicalTable"
              :data="physicalqrcode"
              tooltip-effect="dark"
              style="width: 100%"
              @selection-change="listofqrcodesSelectionChange"
            >
              <el-table-column type="selection" width="55"> </el-table-column>
              <el-table-column
                header-align="center"
                align="center"
                prop="id"
                label="编号"
                width="60"
              ></el-table-column>
              <el-table-column
                header-align="center"
                align="center"
                prop="couponKey"
                label="商家"
              ></el-table-column>
              <el-table-column
                header-align="center"
                align="center"
                prop="couponStatus"
                label="状态"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.couponStatus == 0">未使用</span>
                  <span v-if="scope.row.couponStatus == 1">已使用</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <!-- 分页 -->
          <div class="group">
            <el-pagination
              @size-change="PhysicalSizeChange"
              @current-change="PhysicalCurrentChange"
              :current-page="multiplequery.current"
              :page-sizes="[10, 50, 100]"
              :page-size="multiplequery.size"
              layout="total, sizes, prev, pager, next, jumper"
              :total="multipletotal"
            >
            </el-pagination>
            <!-- 新增 打印 -->
            <div class="btns">
              <el-button
                type="primary"
                @click="printingSelect()"
                size="mini"
                icon="el-icon-printer"
                style="width: 120px; height: 40px"
                >打印当前所选</el-button
              >
              <el-button
                type="primary"
                @click="printingAll()"
                size="mini"
                icon="el-icon-printer"
                style="width: 100px; height: 40px"
                >打印全部</el-button
              >
              <el-button
                type="primary"
                @click="addcoupon()"
                size="mini"
                icon="el-icon-document-add"
                style="width: 80px; height: 40px"
                >新增</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <!--  -->
    <el-dialog
      :visible.sync="isShownumberofqrcodes"
      width="30%"
      v-el-drag-dialog
    >
      <div class="ofqrcodes">
        <div>数量：</div>
        <el-input
          type="Number"
          style="width: 40%"
          v-model="numberofqrcodes"
          placeholder="请输入数量"
          :max="quantitymax"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isShownumberofqrcodes = false">取 消</el-button>
        <el-button type="primary" @click="savePhysicalCoupons()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { findPkInfoSelect, businessList } from "@/api/Parkinglot.js";
import {
  shopCoupon,
  addshopCoupon,
  findByMoneyxx,
  exportShopcoupon,
  shopCouponfindById,
  shopCoupondelete,
  getPkGroupByPkId,
  getShopId,
  shopCouponInvalid,
  PhysicalCouponList,
  savePhysicalCoupon,
} from "@/api/coupon.js";
import printJS from "print-js";
import QRCode from "qrcodejs2"; // 引入qrcode
export default {
  data() {
    var validateName = (rule, value, callback) => {
      let result;
      if (value === "") {
        callback(new Error("请输入折扣值"));
      } else {
        var express = /^0\.\d{1,2}$/;
        result = express.test(value); // 满足条件时候 正则结果是true
        if (result) {
          callback();
        } else {
          callback(new Error("请输入正确折扣值"));
        }
      }
    };
    return {
      k: undefined,
      h5QrcodeUrl: "",
      imageUrl: "",
      businessNameSelect: [],
      isShowyhq: false,
      isShowImprot: false,
      loading: false,
      isShowData: false,
      isShowAdd: false,
      isShowEdit: false,
      isShowbtn: true,
      tableData: [],
      yhqData: undefined,
      startTime: undefined, // 进场时间起始
      endTime: undefined, // 进场时间截至
      expireTime: undefined, //  到期时间
      query: {
        // 查询条件
        current: 1,
        size: 10,
        pkId: undefined, // 停车场id
        shopId: undefined, // 商家名称
        couponType: undefined, // 优惠类型
        startTime: undefined, // 进场时间起始
        endTime: undefined, // 进场时间截至
        isEnable: undefined, // 优惠券状态
      },
      improtquery: {
        pkId: undefined, // 停车场id
        shopName: undefined, // 商户名称
        couponType: undefined, // 优惠类型
        startTime: undefined, // 进场时间起始
        endTime: undefined, // 进场时间截至
      },
      addForm: {
        // 添加表单的绑定数据
        shopId: undefined, // 商家名称
        pkId: undefined, // 停车场
        isMultiUsed: 0, // 是否叠加使用
        couponName: undefined, // 优惠券名称
        couponType: 1, // 优惠方式
        needMoney: undefined, // 满多少金额
        couponMoney: undefined, // 优惠券金额
        quality: undefined, // 优惠券数量
        userMaxNums: 9999, // 每个用户最大领取数
        validTime: undefined, // 领取时效
        validTimeCompany: undefined, //时长单位
        couponTime: undefined, // 领券后有效期
        maxCouponMoney: undefined, // 单张最大的优惠金额
        discountValue: undefined, // 折扣值
        qrcodeType: "1", // 二维码类型
        couponTimeCompany: undefined, //时长单位
        remarks: undefined, // 备注
        AlipayQrcodeUrl: "pages/scancoupons/index", // 备注
        isEnable: 0,
        conponMutiInfos: [
          {
            minConsumeMoney: "",
            maxConsumeMoney: "",
            couponMoney: "",
          },
        ], // 优惠券门槛及金额
      },
      editForm: {
        // 添加表单的绑定数据
        shopId: undefined, // 商家名称
        pkId: undefined, // 停车场
        couponName: undefined, // 优惠券名称
        couponType: undefined, // 优惠方式
        needMoney: undefined, // 满多少金额
        couponMoney: undefined, // 优惠券金额
        quality: undefined, // 优惠券数量
        userMaxNums: undefined, // 每个用户最大领取数
        validTime: undefined, // 领取时效
        validTimeCompany: undefined, //时长单位
        couponTime: undefined, // 领券后有效期
        maxCouponMoney: undefined, // 单张最大的优惠金额
        discountValue: undefined, // 折扣值
        qrcodeType: undefined, // 二维码类型
        couponTimeCompany: undefined, //时长单位
        remarks: undefined, // 备注
        isEnable: 0,
        AlipayQrcodeUrl: "pages/scancoupons/index", // 备注
        conponMutiInfos: [
          {
            minConsumeMoney: "",
            maxConsumeMoney: "",
            couponMoney: "",
          },
        ], // 优惠券门槛及金额
      },
      addshopId: undefined,
      addpkId: undefined,
      formRules: {
        // 添加表单的验证规则
        couponName: [
          { required: true, message: "请输入优惠券名称", trigger: "blur" },
          {
            min: 1,
            max: 99,
            message: "长度在 1 到 99 个字符",
            trigger: "blur",
          },
        ],
        couponType: [
          { required: true, message: "请选择优惠类型", trigger: "change" },
        ],
        discountValue: [
          { required: true, message: "请输入折扣值", trigger: "change" },
          { validator: validateName, trigger: "change" },
        ],
        qrcodeType: [
          { required: true, message: "请选择二维码类型", trigger: "change" },
        ],
        maxCouponMoney: [
          {
            required: true,
            message: "请输入单张最大的优惠金额",
            trigger: "change",
          },
        ],
        needMoney: [
          {
            required: true,
            message: "请输入停车金额满多少元",
            trigger: "blur",
          },
          {
            min: 1,
            max: 99,
            message: "长度在 1 到 99 个字符",
            trigger: "blur",
          },
        ],
        couponMoney: [
          {
            required: true,
            message: "请输入优惠券金额(元)",
            trigger: "blur",
          },
          {
            min: 1,
            max: 99,
            message: "长度在 1 到 99 个字符",
            trigger: "blur",
          },
        ],
        quality: [
          { required: true, message: "请输入优惠券数量", trigger: "blur" },
          {
            min: 1,
            max: 99,
            message: "长度在 1 到 99 个字符",
            trigger: "blur",
          },
        ],
        validTimeCompany: [
          {
            required: true,
            message: "请选择领取时效单位时长",
            trigger: "blur",
          },
        ],
        validTime: [
          { required: true, message: "请选择领取时效", trigger: "blur" },
        ],
        couponTimeCompany: [
          {
            required: true,
            message: "请选择领券后有效期单位",
            trigger: "blur",
          },
        ],
        couponTime: [
          {
            required: true,
            message: "请选择领券后有效期时长",
            trigger: "blur",
          },
        ],
      },
      total: undefined,
      PkInfoSelect: [], // 保存停车场下拉框
      totalCouponMoney: undefined,
      pkNames: undefined, // 保存优惠券适用停车场
      addFormShopId: undefined,
      isShowphysicalCoupon: false, //实体券
      physicalqrcode: [],
      multiplequery: {
        // 查询条件
        current: 1,
        size: 10,
        couponId: undefined,
        // pkId: undefined, // 停车场id
        // shopId: undefined, // 商家名称
        // couponType: undefined, // 优惠类型
        // startTime: undefined, // 进场时间起始
        // endTime: undefined, // 进场时间截至
        // isEnable: undefined, // 优惠券状态
      },
      multipletotal: undefined,
      isShownumberofqrcodes: false, //实体券弹窗
      numberofqrcodes: undefined,
      currentcouponInfo: {
        //当前优惠券信息
      },
      selectcouponsList: [], //当前选中优惠列表
      quantitymax: 0, //发放优惠券最大数
    };
  },
  created() {
    if (this.$cookies.get("user")) {
      this.isShowbtn = true;
    } else {
      this.isShowbtn = false;
    }
    this.findPkInfoSelect();
  },
  methods: {
    // 新增
    addcoupon() {
      this.numberofqrcodes = undefined;
      this.isShownumberofqrcodes = true;
    },
    // 打印当前所选
    printingSelect() {
      const { href } = this.$router.resolve({
        path: `/Physicalcode`,
        query: {
          id: this.multiplequery.couponId,
          setinfo: JSON.stringify(this.currentcouponInfo),
          selectcouponsList: JSON.stringify(this.selectcouponsList),
        },
      });
      window.open(href, "_blank");
    },
    // 打印全部
    printingAll() {
      const { href } = this.$router.resolve({
        path: `/Physicalcode`,
        query: {
          id: this.multiplequery.couponId,
          setinfo: JSON.stringify(this.currentcouponInfo),
        },
      });
      window.open(href, "_blank");
    },
    // 多选单选
    listofqrcodesSelectionChange(value) {
      this.selectcouponsList = value;
    },
    //
    PhysicalSizeChange(val) {
      this.multiplequery.size = val;
      this.getPhysicalCouponList();
    },
    PhysicalCurrentChange(val) {
      this.multiplequery.current = val;
      this.getPhysicalCouponList();
    },
    // 新增二维码
    savePhysicalCoupons() {
      // 最大发放优惠券数量
      if (this.numberofqrcodes > this.quantitymax) {
        this.$message.warning("已超发放优惠券数量");
        return
      }
      if (this.numberofqrcodes != undefined && this.numberofqrcodes != 0) {
        savePhysicalCoupon({
          num: this.numberofqrcodes,
          couponId: this.multiplequery.couponId,
        }).then((res) => {
          if (res.data.code == 200) {
            this.$message.success("添加成功");
          } else {
            this.$message.error("添加失败");
          }
          this.isShownumberofqrcodes = false;
          this.multiplequery.current = 1;
          this.multiplequery.size = 10;
          this.getPhysicalCouponList();
        });
      } else {
        this.$message.warning("请输入正确数量");
      }
    },
    // 查看二维码
    PhysicalCouponList(value) {
      console.log(value.row);
      this.quantitymax = value.row.remainNum;
      this.currentcouponInfo = value.row;
      this.isShowphysicalCoupon = true;
      this.multiplequery.couponId = value.row.id;
      this.multiplequery.current = 1;
      this.multiplequery.size = 10;
      this.getPhysicalCouponList();
    },
    getPhysicalCouponList() {
      this.loading = true;
      PhysicalCouponList(this.multiplequery).then((res) => {
        if (res.data.code == 200) {
          this.physicalqrcode = res.data.data.list;
          this.multiplequery.current = res.data.data.current;
          this.multiplequery.size = res.data.data.size;
          this.multipletotal = res.data.data.total;
          // 默认全选
          this.$nextTick(() => {
            for (let i = 0; i < this.physicalqrcode.length; i++) {
              this.$refs.physicalTable.toggleRowSelection(
                this.physicalqrcode[i],
                true
              );
            }
          });
        }
        this.loading = false;
      });
    },
    // 优惠券类型
    changeType(value) {
      if (value == 1) {
        this.addForm.qrcodeType = "1";
      } else if (value == 2) {
        this.addForm.qrcodeType = undefined;
      } else {
        this.addForm.qrcodeType = "2";
      }
    },
    //停车场选择
    pkIdChange() {
      this.businessList();
    },
    // 商家列表
    businessList() {
      businessList({ pkId: this.query.pkId }).then((res) => {
        this.businessNameSelect = res.data.data.map((el) => {
          return { name: el.shopName, id: el.id };
        });
      });
    },
    // ifrangeTime(value) {
    //   if (value) {
    //     if (this.isInteger(value)) {
    //       return value + '天'
    //     } else {
    //       return value + '小时'
    //     }
    //   } else {
    //     return 0 + '天'
    //   }
    // },
    // isInteger(item) {
    //   if (parseInt(item) == parseFloat(item)) {
    //     return true
    //   } else if (parseInt(item) < parseFloat(item)) {
    //     var after = item.toString().split('.').pop().length
    //     var front = item.toString().split('.')
    //     if (front[0] > 0) {
    //       return true
    //     } else if (after > 1) {
    //       return false
    //     }
    // return item % 1 === 0
    //     return true
    //   }
    // },
    getShopId() {
      getShopId().then((res) => {
        if (res.data.code === "200") {
          if (res.data.data != null) {
            this.addFormShopId = res.data.data.id;
          }
        }
      });
    },
    addOneClick() {
      // 增加一行
      if (this.isShowEdit) {
        this.editForm.conponMutiInfos.push({
          minConsumeMoney: undefined,
          maxConsumeMoney: undefined,
          couponMoney: undefined,
        });
      } else {
        this.addForm.conponMutiInfos.push({
          minConsumeMoney: undefined,
          maxConsumeMoney: undefined,
          couponMoney: undefined,
        });
      }
    },
    delClickone(index) {
      // 删除一行
      if (this.isShowEdit) {
        if (this.editForm.conponMutiInfos.length > 1) {
          this.editForm.conponMutiInfos.splice(index, 1);
        } else {
          this.$message.success("最低保留一条");
        }
      } else {
        if (this.addForm.conponMutiInfos.length > 1) {
          this.addForm.conponMutiInfos.splice(index, 1);
        } else {
          this.$message.success("最低保留一条");
        }
      }
    },
    improtClick() {
      // 导出
      this.isShowImprot = true;
      this.improtquery.pkId = this.query.pkId;
      this.improtquery.shopName = this.query.shopName;
      if (this.startTime || this.endTime) {
        this.improtquery.startTime = this.startTime / 1000;
        this.improtquery.endTime = this.endTime / 1000;
      }
      exportShopcoupon(this.improtquery).then((res) => {
        if (res.data.code === "200") {
          window.open(res.data.data);
        }
        this.isShowImprot = false;
      });
    },
    shopCoupon() {
      this.businessList();
      // 列表
      this.query.startTime = this.startTime;
      this.query.endTime = this.endTime;
      if (this.query.startTime && !this.query.endTime) {
        return this.$message.warning("请输入截止时间");
      } else if (!this.query.startTime && this.query.endTime) {
        return this.$message.warning("请输入开始时间");
      }
      if (this.query.startTime || this.query.endTime) {
        this.query.startTime = this.query.startTime / 1000;
        this.query.endTime = this.query.endTime / 1000;
      }
      shopCoupon(this.query).then((res) => {
        this.tableData = res.data.data.list;
        this.total = res.data.data.total;
        if (this.tableData) {
          this.isShowData = true;
          this.loading = false;
        }
      });
    },
    // 到期时间计算
    computDate(dateStr, day) {
      let str = this.conversionTime(dateStr);
      const num = day * 24 * 3600 * 1000;
      const initiateDate = new Date(str);
      const time = initiateDate.getTime();
      var concludeDate = new Date(time + num);
      let y = concludeDate.getFullYear();
      let MM = concludeDate.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = concludeDate.getDate();
      d = d < 10 ? "0" + d : d;
      let h = concludeDate.getHours();
      h = h < 10 ? "0" + h : h;
      let m = concludeDate.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = concludeDate.getSeconds();
      s = s < 10 ? "0" + s : s;
      const resu = y + "/" + MM + "/" + d + " " + h + ":" + m + ":" + s;
      return resu;
    },
    shopCouponcx() {
      // 查询
      this.query.current = 1;
      this.query.size = 10;
      this.shopCoupon();
    },
    shopCouponcz() {
      // 重置
      this.query.current = 1;
      this.query.size = 10;
      this.query.isEnable = undefined;
      this.query.pkId = this.PkInfoSelect[0].id;
      this.query.shopName = undefined; // 商家名称
      this.query.couponType = undefined; // 优惠类型
      this.startTime = undefined; // 进场时间起始
      this.endTime = undefined; // 进场时间截至
      this.shopCoupon();
    },
    findPkInfoSelect() {
      // 停车场下拉框
      findPkInfoSelect({ allRecord: 0 }).then((res) => {
        if (res.data.code === "200") {
          this.PkInfoSelect = res.data.data;
          this.query.pkId = this.PkInfoSelect[0].id;
          this.shopCoupon();
          this.getShopId();
        }
      });
    },
    handleSizeChange(val) {
      this.loading = true;
      this.query.size = val;
      this.shopCoupon();
    },
    handleCurrentChange(val) {
      this.loading = true;
      this.query.current = val;
      this.shopCoupon();
    },
    jineChange() {
      if (this.isShowAdd) {
        const obj = {
          pkId: this.addForm.pkId,
          shopId: this.addFormShopId,
        };
        findByMoneyxx(obj).then((res) => {
          this.totalCouponMoney = res.data.data.totalCouponMoney;
          getPkGroupByPkId({ pkId: this.addForm.pkId }).then((res) => {
            this.pkNames = res.data.data.map((item) => item.pkName).join(", ");
          });
        });
      } else {
        const obj = {
          pkId: this.editForm.pkId,
          shopId: this.addFormShopId,
        };
        findByMoneyxx(obj).then((res) => {
          this.totalCouponMoney = res.data.data.totalCouponMoney;
          getPkGroupByPkId({ pkId: this.editForm.pkId }).then((res) => {
            this.pkNames = res.data.data.map((item) => item.pkName).join(", ");
          });
        });
      }
    },
    addClose() {
      // 监听添加用户对话框的关闭事件 清空input数据
      this.$refs.addRef.resetFields();
    },
    isShowAddDialog() {
      const obj = {
        pkId: this.query.pkId,
        shopId: this.addFormShopId,
      };
      findByMoneyxx(obj).then((res) => {
        if (res.data.code === "200") {
          this.addshopId = res.data.data.shopName;
          this.addForm.pkId = res.data.data.pkId;
          this.addForm.shopId = res.data.data.shopId;
          this.totalCouponMoney = res.data.data.totalCouponMoney;
          getPkGroupByPkId({ pkId: this.addForm.pkId }).then((res) => {
            this.pkNames = res.data.data.map((item) => item.pkName).join(", ");
            this.isShowAdd = true;
          });
        }
      });
    },
    addClick1() {
      if (this.addForm.couponType === 2) {
        if (
          !this.addForm.conponMutiInfos[0].minConsumeMoney ||
          !this.addForm.conponMutiInfos[0].maxConsumeMoney ||
          !this.addForm.conponMutiInfos[0].couponMoney
        ) {
          return this.$message.warning("请输入消费金额的范围配置");
        }
      } else {
        this.addForm.conponMutiInfos = undefined;
      }
      this.$refs.addRef.validate((valid) => {
        if (!valid) return;
        this.addForm.isEnable = 0;
        if (this.addForm.couponType == 2) {
          this.addForm.couponMoney = 0;
          this.addForm.quality = 0;
        }
        if (this.addForm.validTimeCompany == 0) {
          this.addForm.validTime = (
            parseInt(this.addForm.validTime) / 24
          ).toFixed(2);
        } else {
          this.addForm.validTime = this.addForm.validTime;
        }
        this.addForm.validTimeCompany = undefined;

        if (this.addForm.couponTimeCompany == 0) {
          this.addForm.couponTime = (
            parseInt(this.addForm.couponTime) / 24
          ).toFixed(2);
        } else {
          this.addForm.couponTime = this.addForm.couponTime;
        }
        this.addForm.couponTimeCompany = undefined;

        addshopCoupon(this.addForm).then((res) => {
          if (res.data.code === "200") {
            this.isShowAdd = false;
            this.$message.success("保存成功");
            this.shopCoupon();
          }
        });
      });
    },
    addClick2() {
      if (this.addForm.couponType === 2) {
        if (
          !this.addForm.conponMutiInfos[0].minConsumeMoney ||
          !this.addForm.conponMutiInfos[0].maxConsumeMoney ||
          !this.addForm.conponMutiInfos[0].couponMoney
        ) {
          return this.$message.warning("请输入消费金额的范围配置");
        }
      } else {
        this.addForm.conponMutiInfos = undefined;
      }
      this.$refs.addRef.validate((valid) => {
        if (!valid) return;
        this.$confirm(
          "发放后，商家额度会进行计算，根据发放的优惠券的数据和金额进行计算, 是否继续?",
          "发放",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            this.addForm.isEnable = 1;
            if (this.addForm.validTimeCompany == 0) {
              this.addForm.validTime = (
                parseInt(this.addForm.validTime) / 24
              ).toFixed(2);
            } else {
              this.addForm.validTime = this.addForm.validTime;
            }

            if (this.addForm.couponTimeCompany == 0) {
              this.addForm.couponTime = (
                parseInt(this.addForm.couponTime) / 24
              ).toFixed(2);
            } else {
              this.addForm.couponTime = this.addForm.couponTime;
            }

            if (
              this.addForm.couponMoney * this.addForm.quality >
              this.totalCouponMoney
            ) {
              return this.$message.success("您的优惠券额度不足，请充值");
            }
            this.addForm.validTimeCompany = undefined;
            addshopCoupon(this.addForm).then((res) => {
              if (res.data.code === "200") {
                this.isShowAdd = false;
                this.$message.success("发放成功");
                this.addForm.validTimeCompany = 1;
                this.addForm.conponMutiInfos = [
                  {
                    minConsumeMoney: undefined,
                    maxConsumeMoney: undefined,
                    couponMoney: undefined,
                  },
                ];
                this.shopCoupon();
              }
            });
          })
          .catch(() => {
            this.$message.info("已取消发放");
          });
      });
    },
    editClickIsShow(scope) {
      shopCouponfindById({ id: scope.row.id }).then((res) => {
        // 根据id查询
        if (res.data.code === "200") {
          this.editForm = res.data.data;
          this.editForm.validTime = res.data.data.validTime;
          this.editForm.couponTime = res.data.data.couponTime;
          this.editForm.validTimeCompany = 1;
          this.editForm.couponTimeCompany = 1;
          if (this.editForm.conponMutiInfos) {
            this.editForm.conponMutiInfos = JSON.parse(
              res.data.data.conponMutiInfos
            );
          }
          const obj = {
            pkId: this.editForm.pkId,
            shopId: this.editForm.shopId,
          };
          findByMoneyxx(obj).then((res) => {
            this.totalCouponMoney = res.data.data.totalCouponMoney;
            getPkGroupByPkId({ pkId: this.editForm.pkId }).then((res) => {
              this.pkNames = res.data.data
                .map((item) => item.pkName)
                .join(", ");
              this.isShowEdit = true;
            });
          });
        }
      });
      this.addshopId = scope.row.shopInfo.shopName;
    },
    editClick1() {
      if (this.editForm.couponType === 2) {
        if (
          !this.editForm.conponMutiInfos[0].minConsumeMoney ||
          !this.editForm.conponMutiInfos[0].maxConsumeMoney ||
          !this.editForm.conponMutiInfos[0].couponMoney
        ) {
          return this.$message.warning("请输入消费金额的范围配置");
        }
      } else {
        this.editForm.conponMutiInfos = undefined;
      }
      this.editForm.isEnable = 0;
      if (this.editForm.validTimeCompany == 0) {
        this.editForm.validTime = (
          parseInt(this.editForm.validTime) / 24
        ).toFixed(2);
      } else {
        this.editForm.validTime = this.editForm.validTime;
      }

      if (this.editForm.couponTimeCompany == 0) {
        this.editForm.couponTime = (
          parseInt(this.editForm.couponTime) / 24
        ).toFixed(2);
      } else {
        this.editForm.couponTime = this.editForm.couponTime;
      }

      if (this.editForm.couponType == 2) {
        this.editForm.couponMoney = 0;
        this.editForm.quality = 0;
      }
      this.editForm.validTimeCompany = undefined;
      this.editForm.couponTimeCompany = undefined;
      addshopCoupon(this.editForm).then((res) => {
        if (res.data.code === "200") {
          this.$message.success("保存成功");
          this.shopCoupon();
          this.isShowEdit = false;
        }
      });
    },
    editClick2() {
      if (this.editForm.couponType === 2) {
        if (
          !this.editForm.conponMutiInfos[0].minConsumeMoney ||
          !this.editForm.conponMutiInfos[0].maxConsumeMoney ||
          !this.editForm.conponMutiInfos[0].couponMoney
        ) {
          return this.$message.warning("请输入消费金额的范围配置");
        }
      } else {
        this.editForm.conponMutiInfos = undefined;
      }
      this.$confirm(
        "发放后，商家额度会进行计算，根据发放的优惠券的数据和金额进行计算, 是否继续?",
        "发放",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.editForm.isEnable = 1;
          if (this.editForm.validTimeCompany == 0) {
            this.editForm.validTime = (
              parseInt(this.editForm.validTime) / 24
            ).toFixed(2);
          } else {
            this.editForm.validTime = this.editForm.validTime;
          }
          this.editForm.validTimeCompany = undefined;
          addshopCoupon(this.editForm).then((res) => {
            if (res.data.code === "200") {
              this.$message.success("发放成功");
              this.shopCoupon();
              this.isShowEdit = false;
            }
          });
        })
        .catch(() => {
          this.$message.info("已取消发放");
        });
    },
    delClick(scope) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          shopCoupondelete({ id: scope.row.id }).then((res) => {
            if (res.data.code === "200") {
              this.$message.success("删除成功");
              this.shopCoupon();
            }
          });
        })
        .catch(() => {
          this.$message.info("已取消删除");
        });
    },
    zfClick(scope) {
      this.$confirm(
        "确认要作废此优惠券吗，作废了不能恢复，请慎重操作, 是否确认作废?",
        "作废",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          shopCouponInvalid({ id: scope.row.id }).then((res) => {
            if (res.data.code === "200") {
              this.$message.success("作废成功");
              this.shopCoupon();
            }
          });
        })
        .catch(() => {
          this.$message.info("已取消作废");
        });
    },
    // table h5
    hqh5QrcodeUrl(scope) {
      // 当前时间 加上100天  保证二维码不会过期
      const time = Math.floor(Date.now() / 1000) + 100 * 365 * 24 * 60 * 60;
      let k = this.decimalToBase62(time);
      let query = {
        id: scope.row.id,
        expireTime: this.computDate(scope.row.createTime, scope.row.validTime),
        name: scope.row.couponName,
        discountValue: scope.row.discountValue,
        pkid: scope.row.pkId,
        shopName: scope.row.shopInfo.shopName,
        couponType: scope.row.couponType,
      };
      var domain = window.location.hostname;
      var newPath = "";
      const patt = /jy/;
      if (patt.test(domain)) {
        newPath =
          "https://pk-h5-jy.pingzhi.com" +
          `/#/pages/scancouponspc/index?id=${query.id}&expireTime=${query.expireTime}&name=${query.name}&discountValue=${query.discountValue}&k=${k}&pkid=${query.pkid}`;
      } else {
        newPath =
          process.env.VUE_APP_WEB_API +
          `/#/pages/scancouponspc/index?id=${query.id}&expireTime=${query.expireTime}&name=${query.name}&discountValue=${query.discountValue}&k=${k}&pkid=${query.pkid}`;
      }
      const qrCode = new QRCode(document.createElement("div"), {
        text: newPath,
        width: 200,
        height: 200,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
      let imageUrl = qrCode._el.firstChild.toDataURL();
      return imageUrl;
    },
    yhqClickIsShow(scope) {
      this.generate(scope);
      // 优惠券领取
    },
    generate(scope) {
      // 当前时间 加上100天  保证二维码不会过期
      const time = Math.floor(Date.now() / 1000) + 100 * 365 * 24 * 60 * 60;
      this.k = this.decimalToBase62(time);
      this.getCode(scope);
    },
    // 时间戳加密
    decimalToBase62(decimalNumber) {
      var characters =
        "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
      var base62Value = "";

      while (decimalNumber > 0) {
        var remainder = decimalNumber % 62;
        base62Value = characters.charAt(remainder) + base62Value;
        decimalNumber = Math.floor(decimalNumber / 62);
      }

      return base62Value;
    },
    // 固定二维码
    getCode(scope) {
      let query = {
        id: scope.row.id,
        expireTime: this.computDate(scope.row.createTime, scope.row.validTime),
        name: scope.row.couponName,
        discountValue: scope.row.discountValue,
        pkid: scope.row.pkId,
        shopName: scope.row.shopInfo.shopName,
        couponType: scope.row.couponType,
      };
      var domain = window.location.hostname;
      var newPath = "";
      const patt = /jy/;
      if (patt.test(domain)) {
        newPath =
          "https://pk-h5-jy.pingzhi.com" +
          `/#/pages/scancouponspc/index?id=${query.id}&expireTime=${query.expireTime}&name=${query.name}&discountValue=${query.discountValue}&k=${this.k}&pkid=${query.pkid}`;
      } else {
        newPath =
          process.env.VUE_APP_WEB_API +
          `/#/pages/scancouponspc/index?id=${query.id}&expireTime=${query.expireTime}&name=${query.name}&discountValue=${query.discountValue}&k=${this.k}&pkid=${query.pkid}`;
      }

      const qrCode = new QRCode(document.createElement("div"), {
        text: newPath,
        width: 200,
        height: 200,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
      this.imageUrl = qrCode._el.firstChild.toDataURL();
      this.yhqData = scope.row;
      this.isShowyhq = true;
    },
    dyClick() {
      // 打印
      printJS({
        printable: "printCons",
        type: "html",
        //properties: [
        //    { field: 'name', displayName: '姓名', columnSize:`50%`},
        //    { field: 'sex', displayName: '性别',columnSize:`50%`},
        //],
        // header: `<p class="custom-p"> 名单 </p>`,
        // style: '#printCons {width: 600px;} .no-print{width: 0px} .itemText1 { width: 200px } .itemText2 { width: 200px } .itemText3 { width: 200px } .itemText4 { width: 200px }',
        // gridHeaderStyle:'font-size:12px; padding:3px; border:1px solid; font-weight: 100; text-align:left;',
        // gridStyle:'font-size:12px; padding:3px; border:1px solid; text-align:left;',
        // repeatTableHeader: true,
        // scanStyles:true,
        targetStyles: ["*"],
        // ignoreElements:['no-print','bc','gb']
      });
    },
    // 结束时间选择
    inTimeEndchange() {
      if (this.startTime === null || this.startTime === undefined) {
        this.endTime = null;
        return this.$message.warning("请选择开始时间");
      } else if (this.endTime < this.startTime) {
        this.endTime = this.startTime + 3600 * 1000 * 24 * 1;
        return this.$message.warning("不可选择小于开始时间的日期");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.screen {
  text-decoration: none;
  font-size: 12px;
  color: #409eff;
  margin-right: 10px;
}
.yhq {
  p {
    margin: 0;
    font-size: 16px;
    line-height: 35px;
    span {
      font-weight: 600;
    }
  }
  .yhq-yi {
    display: flex;
    justify-content: space-between;
  }
  .yhqtu {
    display: flex;
    justify-content: space-around;
    .tu-z,
    .tu-y {
      text-align: center;
      p {
        margin: 15px 0;
      }
    }
  }
}
.mains {
  border: 1px solid #ddd;
  padding: 15px;
  box-sizing: border-box;
  margin-left: 60px;
  margin-bottom: 20px;
  .bodfexl {
    display: flex;
    justify-content: space-between;
    position: relative;
    .btn {
      position: absolute;
      right: 0;
      bottom: 10px;
    }
  }
}
::v-deep .el-dialog__body {
  padding: 0 20px 20px;
}
.entitycenter {
  display: flex;
  .codelist {
    width: 100%;
    .listofqrcodes {
    }
  }
  .group {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 20px;
  }
  .btns {
  }
}
.ofqrcodes {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
